<template>
  <div class="content-container">
    <PageTitle :title="$t('MY_CLASSES.TITLE')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('EDIT_CLASS.TITLE') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" @click="attemptLeave()"/>

    <!-- Form -->
    <FormWrapper v-if="initialClassData"
                 :send-form-data="validateData"
                 class="page-form"
                 :on-form-data-update="updatedForm">

      <template #form-content>

        <!-- Name -->
        <!-- <InputField :field-title="$t('CLASSNAME')"
                    :field-name="'name'"
                    :rules="'required'"
                    :type="'text'"
                    :value="initialClassData.name"
                    :placeholder="$t('EDIT_CLASS.NAME_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="nameInUse"
                    :input-icon="require('../../../assets/icons/icn_class.svg')"
                    class="form-element"
                    :on-update="nameUpdated"/> -->

        <InputClassName :original-name="initialClassData.name"
                        :on-input="nameUpdated"
                        :accepted="(value) => nameAvailable = value" />
        <!-- Teacher -->
        <DropdownWithCheckboxes id="teachers"
                                :field-title="$t('EDIT_CLASS.EMAIL_TITLE')"
                                :dropdown-options="teachers"
                                :placeholder="$t('EDIT_CLASS.EMAIL_PLACEHOLDER')"
                                :field-name="'teachers'"
                                option-item="email"
                                :selected-options="initialTeacherOptions"
                                :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                                :callback="(selected) => selectedTeachers = selected"
                                :api-error-text="''"
                                :input-icon="require('../../../assets/icons/icn_account.svg')"
                                class="form-element"/>
       
        <!-- Group -->
        <Dropdown id="group"
                  :field-title="isSpecialClass ? $t('GROUP_COGNITIVE') : $t('GROUP')"
                  :field-text="isSpecialClass ? $t('EDIT_CLASS.COGNITIVE_LEVEL_DESCRIPTION') : ''"
                  :dropdown-options="groups"
                  :placeholder="$t('EDIT_CLASS.GROUP_PLACEHOLDER')"
                  :field-name="'group'"
                  :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                  :input-icon="require('../../../assets/icons/icn_friends.svg')"
                  :selected-option="groups.find((group) => group.id === initialClassData.classTypeId)"
                  :callback="newGroupSelected"
                  :api-error-text="difficultyApiError ? $t(difficultyApiError) : ''"
                  class="form-element"/>

        <div class="two-column-wrapper">
          <!-- Total Students -->
          <InputField :field-title="$t('TOTAL_STUDENTS')"
                      :field-name="'studentCount'"
                      :rules="'required'"
                      :type="'number'"
                      :max="40"
                      :value="initialClassData.studentCount"
                      :placeholder="$t('TOTAL_STUDENTS')"
                      :cy-selector="'students-input'"
                      :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                      :api-error-text="''"
                      :input-icon="require('../../../assets/icons/icn_friends.svg')"
                      class="form-element-column"/>

          <!-- Accessability Type -->
          <Dropdown id="accessibilityType"
                    :field-title="$t('EDIT_CLASS.ASSISTANCE')"
                    :dropdown-options="accessibilityOptions"
                    :placeholder="$t('ASSISTANCE.REGULAR')"
                    :field-name="'accessibilityType'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :callback="accessibilityTypeUpdate"
                    :selected-option="currAccessibilityType"
                    :api-error-text="''"
                    :input-icon="require('../../../assets/icons/icn_gebarentaal.svg')"
                    class="form-element-column"/>
        </div>

        <!-- Sound settings-->
        <ToggleSwitch :field-title="'Extra geluiden in de MediaMissies'"
                      :field-name="'hasExtraSounds'"
                      :value="hasExtraSounds"
                      :callback="onSoundsToggle"/>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('SAVE_CHANGES') }}
        </button>
      </template>

    </FormWrapper>

    <!-- "Save changes?" Warning -->
    <BasicModal v-if="modalEnabled"/>
    <LeaveEarlyModal :values-to-check="leaveEarlyValuesToCheck" :result-callback="leaveEarlyResult"/>

  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputField from '@/components/yo-form/InputField'
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import Dropdown from '@/components/yo-form/Dropdown'
import { useI18n } from 'vue-i18n'
import DropdownWithCheckboxes from '@/components/yo-form/DropdownWithCheckboxes'
import InputClassName from '@/components/elements/inputFields/InputClassName'
// import {
//   RESET_ACTIVE_BASIC_MODAL,
//   RESET_BASIC_MODAL_DATA
// } from '@/store/mutations'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import ApiHandler from '../../../utils/handlers/ApiHandler'
import { useRouter, useRoute } from 'vue-router'
import LeaveEarlyModal from '@/components/elements/basicModal/LeaveEarlyModal'
import { watch } from '@vue/runtime-core'
// import nl from '@/utils//language/nl.json'
import ToggleSwitch from '@/components/yo-form/ToggleSwitch'
import {SET_TOAST_DATA} from "@/store/mutations";
import { GET_CLASS_TYPES } from '@/store/actions'

export default {
  name: 'EditClass',
  components: {
    BasicModal,
    DropdownWithCheckboxes,
    Dropdown,
    FormWrapper,
    BackButton,
    PageTitle,
    InputField,
    LeaveEarlyModal,
    InputClassName,
    ToggleSwitch
  },
  setup() {
    const { t } = useI18n({})
    const store = useStore()
    const router = useRouter()
    store.dispatch(GET_CLASS_TYPES);

    const route = useRoute()
    const leaveEarlyValuesToCheck = ref([])
    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const selectedGroup = ref({})
    const difficultyApiError = ref('')

    const modalEnabled = computed(() =>
      activeModal.value === MODAL_NAMES.EDIT_CLASS_CONFIRM
    )

    const classId = route.params.classId
    /** Teachers Dropdown **/
    const teachers = ref([])
    /** Group Dropdown **/
    const isSpecialClass = computed(() => store.getters.getTeacherProfile.isSpecialEducationSchool)
    const groups = computed(() => store.getters.getClassTypes);

    const initialClassData = ref()
    const currentFormData = ref({})
    const selectedTeachers = ref([])
    const initialTeacherOptions = computed(() => initialClassData.value.linkedTeachers)


    watch(() => initialClassData.value, (curr, prev) => {
      console.log('initialClassData.value', initialClassData.value)
      currentFormData.value = curr
      selectedGroup.value.id = curr.classTypeId
      currAccessibilityType.value = curr.accessibilityType
      hasExtraSounds.value = curr.hasExtraSounds === 1
    })

    function newGroupSelected(group) {
      selectedGroup.value.id = group?.id
    }

    Promise.all([
      ApiHandler.get('teacher/get-teachers-of-school'),
      ApiHandler.get(`teacher/get-school-class/${classId}`)
    ]).then((result) => {
      teachers.value = result[0].data.filter(value => value.name)
      initialClassData.value = result[1].data
    })

    /** Accessability Type **/
    const accessibilityOptions = [
      { id: 1, name: t('ASSISTANCE.REGULAR') },
      { id: 2, name: t('ASSISTANCE.SIGN_LANGUAGE') },
      { id: 3, name: t('ASSISTANCE.AUDIO_DESCRIPTION') },
      { id: 4, name: t('ASSISTANCE.SUBTITLES') }
    ]

    const currAccessibilityType = ref(accessibilityOptions[0])

    function accessibilityTypeUpdate(selected) {
      // console.log('selected', selected);
      currAccessibilityType.value = selected
    }

    const hasExtraSounds = ref(true)
    function onSoundsToggle(newToggleVal) {
      hasExtraSounds.value = newToggleVal
    }

    /** Form **/
    function validateData(data) {
      if (!nameAvailable.value) {
        return
      }
      modalController.value.setModal(
        BASIC_MODAL_CONTENT.EDIT_CLASS_CONFIRM,
        () => onEditClass(data),
        null, null, MODAL_NAMES.EDIT_CLASS_CONFIRM)
    }


    const currentName = ref('')
    const nameAvailable = ref(true)
    function nameUpdated(value) {
      currentName.value = value
    }


    /** Edit **/
    // Fired when the user clicks on the confirm button of the warning.
    function onEditClass(data) {
      // editClass().then()
      modalController.value.resetActiveModal()
      var form = new FormData()
      form.append('id', initialClassData.value.id)
      form.append('name', currentName.value && currentName.value !== '' ? currentName.value : initialClassData.value.name)
      for (let i = 0; i < selectedTeachers.value.length; i++) {
        form.append(`teachers[${i}]`, selectedTeachers.value[i].id)
      }
      form.append('accessibilityTypeId', currAccessibilityType.value.id)
      form.append('studentCount', data.studentCount)

      const groupNames = groups.value.map((group) => group.name)
      const index = groupNames.indexOf(data.group)
      form.append('classTypeId', groups.value[index].id)
      form.append('hasExtraSounds', hasExtraSounds.value)

      ApiHandler.post('teacher/edit-school-class', form).then(response => {
        if (response) {
          store.commit(SET_TOAST_DATA, { text: `Schoolklas "${response.data.name}" succesvol aangepast`, src: require('@/assets/icons/icn_successful.svg') })
          router.push({ name: ROUTE_NAMES_TEACHER_PORTAL.MY_CLASSES })
        }
      }).catch(error => {
        switch (error.response?.data?.error) {
          case 'cannot_change_class_difficulty_with_points':
            difficultyApiError.value = 'EDIT_CLASS.EDIT_CLASS_ERROR'
            break
          default:
            console.log(error.response)
            break
        }
      })
    }

    function getChangeValues() {
      const changedTitle = initialClassData.value.name !== currentFormData.value.name
      const changedStudentCount = initialClassData.value.studentCount !== currentFormData.value.studentCount
      const changedAccessabilityType = initialClassData.value.accessibilityType !== currentFormData.value.accessibilityType
      const changedGroup = initialClassData.value.classTypeId !== selectedGroup.value.id
      const oldTeachers = initialTeacherOptions.value
      const newTeachers = selectedTeachers.value
      const teachersChanged = !(oldTeachers.every(old => newTeachers.some(newTeacher => old.id === newTeacher.id)) && oldTeachers.length === newTeachers.length)
      return [changedTitle, changedStudentCount, teachersChanged, changedAccessabilityType, changedGroup]
    }


    function attemptLeave() {
      leaveEarlyValuesToCheck.value = getChangeValues()
    }

    function leaveEarlyResult(value) {
      if (value) {
        router.push({ name: ROUTE_NAMES_TEACHER_PORTAL.MY_CLASSES })
      } else {
        leaveEarlyValuesToCheck.value = []
      }
    }

    function updatedForm(data) {
      difficultyApiError.value = ''
      currentFormData.value = data
    }

    return {
      ROUTE_NAMES_TEACHER_PORTAL,

      /** Teachers Dropdown **/
      teachers,

      /** Group Dropdown **/
      groups,
      isSpecialClass,
      difficultyApiError,

      /** Accessibility Type **/
      accessibilityOptions,
      currAccessibilityType,
      accessibilityTypeUpdate,

      /** Sounds **/
      hasExtraSounds,
      onSoundsToggle,

      initialClassData,
      initialTeacherOptions,
      selectedTeachers,
      /** Form **/
      validateData,

      /** early leave checkers */
      attemptLeave,
      leaveEarlyResult,
      updatedForm,
      leaveEarlyValuesToCheck,
      modalEnabled,
      newGroupSelected,
      nameUpdated,
      nameAvailable
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.edit-form {
  margin-top: rem(32);
  width: rem(492);

  .form-element {
    &:not(:last-of-type) {
      margin-bottom: rem(28);
    }
  }

  .edit-button {
    margin-top: rem(40);
  }
}

:deep(.form-element) {
  position: relative;
}

.two-column-wrapper {
  margin-bottom: rem(32);
}

.divider {
  background-color: var(--purple_light);
  border: none;
  height: rem(2);
  opacity: 30%;
  margin: rem(40) 0;
}
</style>
